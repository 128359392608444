import { Suspense } from 'react';
import { Spin } from '@douyinfe/semi-ui';
import { IconLoading } from '@douyinfe/semi-icons';

const PageLoading = () => {
    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100vh',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Spin indicator={<IconLoading />} />
            <div style={{ fontSize: '12px', color: 'gray' }}>请耐心等待...</div>
        </div>
    );
}

const PageLoader = (Component) => (props) => {

    return (
        <Suspense fallback={PageLoading()}>
            <Component {...props} />
        </Suspense>

    );
}

export default PageLoader;
