import { useRoutes } from 'react-router-dom';
import routes from './routers';
import getRouter from './utils/utils';

const App = (props) => {

    const content = useRoutes(routes);

    return content;
}

export default App;
