import React from 'react';
import { Avatar } from '@douyinfe/semi-ui';

const GoodsCattleLogo = (props) => {

    return (
        <Avatar src="https://minio.goodscattle.com/static/logo/goodscattle_logo.png" />
    );
}

export default GoodsCattleLogo;
