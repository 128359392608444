import { lazy } from 'react';
import GoodsCattleAuth from '../components/GoodsCattleAuth';

import merchantRoutes from './merchant';
import homeRoutes from './home';

import PageLoader from '../components/PageLoader';
import MerchantLayout from '../layouts/MerchantLayout';
import HomeLayout from '../layouts/HomeLayout';

const NotFoundPage = PageLoader(lazy(() => import('../pages/error/NotFound')));


const routes = [
    {
        path: '/',
        element: <HomeLayout />,
        children: homeRoutes
    },
    {
        path: '/merchant',
        element: (
            <GoodsCattleAuth>
                <MerchantLayout />
            </GoodsCattleAuth>
        ),
        children: merchantRoutes
    },
    {
        path: '*',
        element: <NotFoundPage />
    }
];

export default routes;