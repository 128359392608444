import { Outlet } from 'react-router-dom';
import { Layout, Nav, Button, Breadcrumb, Avatar } from '@douyinfe/semi-ui';
import { IconBell, IconHelpCircle, IconBytedanceLogo, IconHome, IconLive, IconSetting } from '@douyinfe/semi-icons';
import { Link } from "react-router-dom";
import merchantRoutes from '../routers/merchant';
import GoodsCattleLogo from '../components/GoodsCattleLogo';
import { useLocation } from 'react-router-dom';

const OrganizationAdminLayout = (props) => {

    const { Header, Footer, Content } = Layout;

    const location = useLocation();

    return (
        <Layout style={{ border: 'none', margin: 0, padding: 0 }}>
            <Header style={{ backgroundColor: 'var(--semi-color-bg-1)' }}>
                <div>
                    <Nav mode="horizontal"
                        renderWrapper={({ itemElement, isSubNav, isInSubNav, props }) => {
                            const routerMap = {
                                Home: "/",
                                About: "/about",
                                Dashboard: "/dashboard",
                                "Nothing Here": "/nothing-here"
                            };
                            return (
                                <Link
                                    style={{ textDecoration: "none" }}
                                    to={routerMap[props.itemKey]}
                                >
                                    {itemElement}
                                </Link>
                            );
                        }}
                        items={merchantRoutes}
                    >
                        <Nav.Header>
                            <GoodsCattleLogo />
                        </Nav.Header>
                        <Nav.Footer>
                            <Button
                                theme="borderless"
                                icon={<IconBell size="large" />}
                                style={{
                                    color: 'var(--semi-color-text-2)',
                                    marginRight: '12px',
                                }}
                            />
                            <Button
                                theme="borderless"
                                icon={<IconHelpCircle size="large" />}
                                style={{
                                    color: 'var(--semi-color-text-2)',
                                    marginRight: '12px',
                                }}
                            />
                            <Avatar color="orange" size="small">
                                YJ
                            </Avatar>
                        </Nav.Footer>
                    </Nav>
                </div>
            </Header>
            <Content
                style={{
                    padding: '24px'
                }}>
                    <Outlet />
            </Content>
            <Footer
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '20px',
                    color: 'var(--semi-color-text-2)'
                }}
            >
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <IconBytedanceLogo size="large" style={{ marginRight: '8px' }} />
                    <span>Copyright © 2023 GoodsCattle. All Rights Reserved. </span>
                </span>
                <span>
                    <span style={{ marginRight: '24px' }}>平台客服</span>
                    <span>反馈建议</span>
                </span>
            </Footer>
        </Layout>
    );
};

export default OrganizationAdminLayout;