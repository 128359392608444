import { lazy } from 'react';
import { IconHome, IconApartment, IconUserGroup, IconSetting, IconCreditCard } from '@douyinfe/semi-icons';

import PageLoader from '../components/PageLoader';

const IndexPage = PageLoader(lazy(() => import('../pages/home')));

const AboutPage = PageLoader(lazy(() => import('../pages/home/about')));
const EntryPage = PageLoader(lazy(() => import('../pages/home/entry')));

const merchantRoutes = [
    {
        path: '',
        itemKey: 'homeIndex',
        text: '首页',
        name: '首页',
        icon: <IconHome />,
        element: <IndexPage />
    },
    {
        path: 'about',
        itemKey: 'about',
        text: '关于',
        name: '关于',
        icon: <IconHome />,
        element: <AboutPage />
    },
    {
        path: '/home/entry',
        itemKey: 'entry',
        text: '入驻',
        name: '入驻',
        icon: <IconApartment />,
        element: <EntryPage />
    },
]

export default merchantRoutes;