import { lazy } from 'react';
import { IconHome, IconApartment, IconUserGroup, IconSetting, IconCreditCard } from '@douyinfe/semi-icons';

import PageLoader from '../components/PageLoader';
const MerchantIndexPage = PageLoader(lazy(() => import('../pages/merchant')));

const merchantRoutes = [
    {
        path: '/merchant/index',
        itemKey: 'merchantIndex',
        text: '商家首页',
        name: '商家首页',
        icon: <IconHome />,
        element: <MerchantIndexPage />
    },
    {
        path: '/merchant/site',
        itemKey: 'siteIndex',
        text: '站点管理',
        name: '站点管理',
        icon: <IconApartment />,
        element: <MerchantIndexPage />
    },
    {
        path: 'employee',
        itemKey: 'employeeIndex',
        text: '员工管理',
        name: '员工管理',
        icon: <IconUserGroup />,
        element: <MerchantIndexPage />
    },
    {
        path: 'finance',
        itemKey: 'financeIndex',
        text: '财务管理',
        name: '财务管理',
        icon: <IconCreditCard />,
        element: <MerchantIndexPage />
    },
    {
        path: 'setting',
        itemKey: 'settingIndex',
        text: '商家设置',
        name: '商家设置',
        icon: <IconSetting />,
        element: <MerchantIndexPage />
    },
]

export default merchantRoutes;