import { Outlet } from 'react-router-dom';
import { Layout, Nav, Typography } from '@douyinfe/semi-ui';
import GoodsCattleLogo from '../components/GoodsCattleLogo';
const { Text } = Typography;

const HomeLayout = (props) => {
    const { Header, Footer, Content } = Layout

    return (
        <Layout style={{ border: 'none', margin: 0, padding: 0 }}>
            <Header style={{ backgroundColor: 'var(--semi-color-bg-1)' }}>
                <div>
                    <Nav
                        mode="horizontal"
                        header={{
                            logo: <GoodsCattleLogo />,
                            text: '物牛',
                        }}
                    >
                    </Nav>
                </div>
            </Header>
            <Content
                style={{
                    padding: '24px',
                    display: 'flex',
                }}
            >
                <Outlet />
            </Content>
            <Footer
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '20px 60px',
                    color: 'var(--semi-color-text-2)',
                }}
            >
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '20px'
                    }}
                >
                    <span>Copyright © 2023 物牛网络科技 GoodsCattle. All Rights Reserved. </span>
                    <Text link={{ href: 'https://beian.miit.gov.cn/' }}>鲁ICP备2022017952号</Text>
                </span>
                <span>
                    <span style={{ marginRight: '24px' }}>平台客服</span>
                    <span>反馈建议</span>
                </span>
            </Footer>
        </Layout>
    )
}

export default HomeLayout
