import React, { useState, useEffect } from 'react';

const GoodsCattleAuth = (props) => {
    const { children } = props;
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        console.log('check user login');

        return () => {

        }
    }, [])


    return children;
}

export default GoodsCattleAuth;
